.hero-container {
  text-align: left;
  min-height: 50vh;
  background-color: #dbdbdb;
  padding: 16px 32px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px -1px rgba(0, 0, 0, 0.1);
}

.glass-bg {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

.hero-body {
  padding-top: 30px;
  padding-bottom: 150px;
}

.supporting-text {
  font-size: 24px;
}
